export const environment = {
  stand: 'demo',
  lastBuildTime: '03-04-2025 18:26:00',
  version: '25.3.0',
  production: true,
  traceRoute: true,
  hrmImageEndpoint: 'https://hrm.bimteam.ru/api/v2',
  fmEndpoint: 'https://fm-api.bimteam.app/v1',
  locale: 'en-US',
  metrikaWebvisor: true,
  isGoogleIntegrationEnabled: true,
  isSaas: window['env']['isSaas'] === 'true' ? true : false,

  auth: {
    silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
    redirectUri: window.location.origin + '/implicit-redirect',
    requireHttps: false,
    showDebugInformation: true,
    disableAtHashCheck: true,
    sendAccessToken: true,
    issuer: 'https://auth.bimteam.app/realms/master',
    clientId: 'fm_api',
    scope: 'email',
    responseType: 'id_token token',
    allowedUrls: ['https://fm-api.bimteam.app'],
  },
};
