<div class="files" *ngIf="chatAttachmentsService.attachments?.length">
  <chat-file-item
    *ngFor="let file of chatAttachmentsService.attachments; let index = index"
    [name]="file.name"
    [removable]="true"
    (remove)="removeHandler(file, index)"
    (open)="downloadFile(file)"
  >
  </chat-file-item>
</div>

<pik-button-group class="uploader">
  <input
    accept=".txt, .csv, .rfa, .rvt,  .rfe, .rte, .dwg, .pdf, .png, .jpeg, .xlsx, .doc, .docx, .pptx"
    type="file"
    class="fileInput"
    #fileInput
    (change)="uploadFiles($event.target.files)"
    multiple
  />
  <pik-loader *ngIf="chatAttachmentsService.pending$ | async"></pik-loader>
</pik-button-group>
