import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { catchError, map, take } from 'rxjs/operators';

import { ImageDataInterface, MaterialParameterDto, ResultPart } from '@common/interfaces';
import { IdNameDto, MaterialDto, MaterialTableDto, MaterialUpdateNormalizedDto, PatchOperation } from '@common/dto';
import { MaterialsFilterParams } from '@common/models/filters';
import { ApiPaths } from '@common/consts/api-paths';

@Injectable({ providedIn: 'root' })
export class MaterialsApiService {
  constructor(private http: HttpClient, private _sanitized: DomSanitizer) {}

  /**
   * Возвращает результат поиска по фильтру, по умолчанию все элементы
   * @param filter - фильтр
   */
  public getList(filter?: MaterialsFilterParams): Observable<ResultPart<MaterialTableDto>> {
    const params = filter ? filter.toApiParams() : {};
    return this.http.post<ResultPart<MaterialTableDto>>(`${ApiPaths.Materials}/filter`, params);
  }

  public smartSearch(query: string): Observable<IdNameDto[]> {
    return this.http.get<IdNameDto[]>(`${ApiPaths.Materials}/smartSearch`, {
      params: {
        searchString: query,
      },
    });
  }

  /**
   * Возвращает наборы параметров материала
   * @param materialId - id
   */
  public getParameters(materialId: number) {
    return this.http.get<ResultPart<MaterialParameterDto>>(`${ApiPaths.Materials}/${materialId}/parameters`);
  }

  /**
   * Возвращает материал по идентификатору
   * @param materialId - id
   */
  public getById(materialId: number): Observable<MaterialDto> {
    return this.http.get<MaterialDto>(`${ApiPaths.Materials}/${materialId}`);
  }

  /**
   * Удаляет материал
   * @param materialId - id материала
   */
  public file(materialId: number) {
    return this.http.get(`${ApiPaths.Materials}/${materialId}/file`, { responseType: 'blob' });
  }

  /**
   * Обновляет материал
   * @param patchData - изменения
   * @param materialId - id материала
   */
  public patch(materialId: number, patchData: PatchOperation[]) {
    return this.http.patch<MaterialDto>(`${ApiPaths.Materials}/${materialId}`, patchData);
  }

  /**
   * Создает новый материал
   * @param material
   */
  public create(material: MaterialUpdateNormalizedDto): Observable<MaterialDto> {
    return this.http.post<MaterialDto>(ApiPaths.Materials, material);
  }

  /**
   * Удаляет материал
   * @param materialId - id материала
   */
  public remove(materialId: number): Observable<void> {
    return this.http.delete<void>(`${ApiPaths.Materials}/${materialId}`);
  }

  /**
   * Возвращает картинку материала
   * @param materialId
   */
  public preview(materialId: number) {
    return this.http.get(`${ApiPaths.Materials}/${materialId}/preview`, { responseType: 'blob' });
  }

  public previewDefault() {
    return this.http.get('./assets/images/box.png', { responseType: 'blob' });
  }

  setImgData(data, isDefault = false): ImageDataInterface {
    return {
      file: data,
      styleUrl: this._sanitized.bypassSecurityTrustStyle('url(' + URL.createObjectURL(data) + ')'),
      url: this._sanitized.bypassSecurityTrustUrl(URL.createObjectURL(data)),
      resourceUrl: this._sanitized.bypassSecurityTrustResourceUrl(URL.createObjectURL(data)),
      default: isDefault,
    };
  }

  /**
   * Возвращает иконку прредпросмотра материала
   * @param materialId - id
   */
  public getImage(materialId: number): Observable<ImageDataInterface> {
    return this.preview(materialId).pipe(
      take(1),
      map((data) => (data ? this.setImgData(data) : null)),
      catchError(() =>
        this.previewDefault().pipe(
          take(1),
          map((data) => (data ? this.setImgData(data, true) : null)),
        ),
      ),
    );
  }

  /**
   * Восстанавливает материал из удалённых
   * @param materialId - id
   */
  public restore(materialId: number): Observable<MaterialDto> {
    return this.http.post<MaterialDto>(`${ApiPaths.Materials}/unarchive/${materialId}`, {});
  }

  /**
   * Загружает файл в хранилище
   * @param id - ID материала
   * @param image - файл картинки
   * @param forceUpdate - Флаг обновления изображения
   */
  public uploadImage(id: number, image: File, forceUpdate = true): Observable<void> {
    const formData = new FormData();
    formData.append('file', image);

    return this.http.post<void>(`${ApiPaths.Materials}/${id}/image?forceUpdate=${forceUpdate}`, formData);
  }

  public createGoogleSheet(filter: MaterialsFilterParams): Observable<string> {
    const params = filter ? filter.toApiParams() : {};
    return this.http.post<string>(`${ApiPaths.Materials}/sheets`, params);
  }

  public createGoogleSheetFromFt(filter: Partial<MaterialsFilterParams>) {
    return this.http.post(`${ApiPaths.Materials}/sheets`, filter);
  }

  public createExcelSheetFromFt(filter: Partial<MaterialsFilterParams>): Observable<Blob> {
    return this.http.post(`${ApiPaths.Materials}/excel`, filter, { responseType: 'blob' });
  }

  /**
   * Импортирует электронную таблицу материалов
   * @param sheetId - id электронной таблицы
   */
  public importSheet(sheetId: string): Observable<void> {
    return this.http.put<void>(`${ApiPaths.Materials}/sheets/${sheetId}`, null);
  }

  /**
   * Возвращает материалы по id[]
   * @param ids - айди материалов
   */
  public getIdNames(ids: number[]): Observable<IdNameDto[]> {
    return this.http.get<IdNameDto[]>(`${ApiPaths.Materials}/idnames`, {
      params: {
        ids: ids.map((id) => id.toString()),
      },
    });
  }

  public createExcelSheet(filter: MaterialsFilterParams): Observable<Blob> {
    const params = filter ? filter.toApiParams() : {};
    return this.http.post(`${ApiPaths.Materials}/excel`, params, { responseType: 'blob' });
  }

  public importExcelSheet(file: File) {
    const data = new FormData();
    data.append('file', file);
    return this.http.put(`${ApiPaths.Materials}/excel`, data);
  }
}
