import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import * as MarkdownIt from 'markdown-it';
import { ImagesViewerService } from '../utils/images-viewer.service';
import { Markdown } from '../utils/markdown';
import { HeapFilesApiService } from '@services/api';
import { pipe, tap } from 'rxjs';

const md = MarkdownIt();
md.set({ html: true });

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'markdown-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    class: 'pik-markdown-viewer',
    '[class.nowrap]': 'whiteSpace === "nowrap"',
    '[class.pre-wrap]': 'whiteSpace === "pre-wrap"',
    '[class.ellipsis]': 'ellipsis',
  },
})
export class MarkdownViewerComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() removeMarkdown: boolean;
  @Input() removeMargin: boolean;

  @Input() data: string;

  @Input() ellipsis = false;

  @Input() whiteSpace = '';

  @ViewChild('viewerRef')
  viewerRef: ElementRef<HTMLElement>;
  public convertedData: string;

  constructor(private imagesViewer: ImagesViewerService, private heapFilesApiService: HeapFilesApiService) {}

  ngOnChanges() {
    if (!this.data) {
      this.data = '';
    }

    if (this.removeMarkdown) {
      this.convertedData = Markdown.removeTags(this.data.toString());
      return;
    }

    if (this.removeMargin) {
      this.convertedData = (this.data ? md.render(this.data.toString()) : '').replace(/[\r\n]/g, '');
      return;
    }

    this.convertedData = (this.data ? md.render(this.data.toString()) : '')
      .replace(/[\r\n]/g, '<br/>')
      .replace(/<p>(https?\:\/\/\S+)<\/p>/g, '<a href="$1">$1</a>')
      .replace(/(?<!")(?<!">)\b(https?\:\/\/\S+)/g, '<a href="$1">$1</a>');

    this.init();
  }

  ngAfterViewInit() {
    this.init();
  }

  init() {
    const viewerElement = this.viewerRef?.nativeElement;
    if (viewerElement) {
      setTimeout(() => {
        this.imagesViewer.init(viewerElement.querySelectorAll('img'));
      }, 400);
    }
  }

  ngOnDestroy() {
    this.imagesViewer.destroy();
  }
}
