export function quillConfigFactory(useImages: boolean = false, useMention: boolean = false, scrollbarId: string) {
  const quillConfig = {
    placeholder: this.placeholder,
    scrollingContainer: `#editor-scrollbar-${scrollbarId} > div`,
    modules: {
      magicUrl: true,
      toolbar: {
        container: this._toolbarRef.nativeElement,
        handlers: {
          link: (value) => this._linkHandler(value),
          file: (value) => this._fileHandler(value),
        },
      },
      uploader: {
        handler: this._imageHandlerPaste,
      },
    },
  };

  // change editor modules
  if (useMention) {
    Object.assign(quillConfig.modules.toolbar.handlers, {
      mention: () => this._startMention(),
    });

    Object.assign(quillConfig.modules, {
      mention: {
        allowedChars: /^[а-яА-ЯЁёA-Za-z]*$/,
        source: (searchTerm: string) => this.mentionService.showSuggestionList(searchTerm),
      },
    });
  }

  if (useImages) {
    Object.assign(quillConfig.modules.toolbar.handlers, {
      image: () => this._imageHandler(),
    });
  }

  return quillConfig;
}
