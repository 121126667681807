import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AccountService, PermissionService } from '@core/account/services';
import { filter, map, Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { ROUTES_PARTS } from '@common/consts';
import { UserDto } from '@common/dto';

@Injectable({
  providedIn: 'root',
})
export class SaasGuard implements CanActivate {
  constructor(
    private userService: AccountService,
    private router: Router,
    private permissionService: PermissionService,
  ) {}
  canActivate(): Observable<boolean> {
    return this.userService.user$.pipe(
      filter((item) => !!item),
      map((user: UserDto) => {
        if (!this.permissionService.access.Saas) {
          this.router.navigate([`${ROUTES_PARTS.NOT_FOUND}`], { skipLocationChange: true });
          return false;
        }
        return true;
      }),
    );
  }
}
