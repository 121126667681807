import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MessageDto } from '@common/dto';
import { OpenTabData, TabType } from '@common/interfaces';
import { ChatAttachmentsService } from '../chat-attachments/chat-attachments.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMessageComponent {
  attachments = new BehaviorSubject([]);
  @Input() showAttach = false;
  @Input() set message(message: MessageDto) {
    this._message = {
      ...message,
      message: message.message.split('/\n').join(' </br>'),
    };

    this.appOpenTab = {
      type: TabType.USERS,
      entity: {
        id: message.userId,
        name: message.fio,
      },
    };

    if (this.showAttach) {
      this.chatAttachmentsService.getAttachments(message.id).subscribe((a) => this.attachments.next(a));
    }
  }

  constructor(public chatAttachmentsService: ChatAttachmentsService) {}

  get message() {
    return this._message;
  }

  appOpenTab: OpenTabData;

  private _message: MessageDto;
}
