import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ApiPaths } from '@common/consts';
import { IdNameDto, NomenclatureCreateDto, NomenclatureDto, NomenclatureUpdateDto } from '@common/dto';
import { ResultPart } from '@common/interfaces';
import { NomenclaturesFilterParams } from '@common/models/filters';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NomenclaturesApiService {
  constructor(private http: HttpClient) {}

  /**
   * Возвращает характеристики по фильтру.
   * @param filter - фильтр
   */
  public getList(filter?: NomenclaturesFilterParams) {
    const params = filter ? filter.toApiParams() : {};
    return this.http.post<ResultPart<NomenclatureDto>>(`${ApiPaths.Nomenclatures}/filter`, params);
  }

  /**
   * Возвращает объект по ID.
   * @param entityId - id
   */
  public getById(entityId: number) {
    return this.http.get<NomenclatureDto>(`${ApiPaths.Nomenclatures}/${entityId}`);
  }

  /**
   * Добавляет новый объект
   * @param entity - новый объект
   */
  public create(entity: NomenclatureCreateDto) {
    return this.http.post<NomenclatureDto>(ApiPaths.Nomenclatures, entity);
  }

  /**
   * Обновляет объект
   * @param entity - изменённые данные
   */
  public update(entity: NomenclatureUpdateDto) {
    return this.http.put<NomenclatureDto>(`${ApiPaths.Nomenclatures}`, entity);
  }

  /**
   * Удаляет объект
   * @param entityId - id
   */
  public remove(entityId: number) {
    return this.http.delete<void>(`${ApiPaths.Nomenclatures}/${entityId}`);
  }

  /**
   * Возвращает параметры по id[]
   * @param ids - ids номенклатур
   */
  public getIdNames(ids: number[]): Observable<IdNameDto[]> {
    return this.http.get<IdNameDto[]>(`${ApiPaths.Nomenclatures}/idnames`, {
      params: {
        ids: ids.map((id) => id.toString()),
      },
    });
  }

  public restore(id: number): Observable<NomenclatureUpdateDto> {
    return this.http.post<NomenclatureUpdateDto>(`${ApiPaths.Nomenclatures}/unarchive/${id}`, {});
  }
}
