import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import {
  PikButtonsModule,
  PikDropdownModule,
  PikInputModule,
  PikLoaderModule,
  PikOptionModule,
  PikTooltipModule,
} from '@pik-ui/ng-components';

import { EmbedLinkComponent } from './embed-link/embed-link.component';
import { MarkdownEditorComponent } from './editor.component';
import { TranslateModule } from '@ngx-translate/core';
import { ChatAttachmentsComponent } from '@shared/components/chat/components/chat-attachments/chat-attachments.component';
import { ChatFileItemComponent } from '@shared/components/chat/components/chat-file-item';
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
  declarations: [MarkdownEditorComponent, EmbedLinkComponent, ChatAttachmentsComponent, ChatFileItemComponent],
  imports: [
    CommonModule,
    AngularSvgIconModule,
    PikLoaderModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    PikButtonsModule,
    PikInputModule,
    PikTooltipModule,
    OverlayModule,
    PikOptionModule,
    PikDropdownModule,
    TranslateModule,
  ],
  exports: [MarkdownEditorComponent],
})
export class MarkdownEditorModule {}
