import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

import { ResultPart } from '@common/interfaces';
import { ApiPaths } from '@common/consts';
import { StandardsFilterParams } from '@common/models/filters';
import { StandardCreateDto, StandardDto, StandardUpdateDto } from '@common/dto';

@Injectable({ providedIn: 'root' })
export class StandardsApiService {
  constructor(private http: HttpClient, private _sanitized: DomSanitizer) {}

  /**
   * Возвращает заданное количество объектов, по умолчанию возвращает все.
   * @param filter - фильтр
   */
  public getList(filter?: StandardsFilterParams) {
    const params = filter ? filter.toApiParams() : {};
    return this.http.post<ResultPart<StandardDto>>(`${ApiPaths.Standards}/filter`, params);
  }

  /**
   * Возвращает объект по ID.
   * @param id - id стандарта
   */
  public getById(id: number) {
    return this.http.get<StandardDto>(`${ApiPaths.Standards}/${id}`);
  }

  /**
   * Удаляет объект по ID.
   * @param id - id стандарта
   */
  public delete(id: number) {
    return this.http.delete(`${ApiPaths.Standards}/${id}`);
  }

  /**
   * Создает новый стандарт
   * @param data - новый стандарт
   */
  public create(data: StandardCreateDto): Observable<StandardDto> {
    return this.http.post<StandardDto>(`${ApiPaths.Standards}`, data);
  }

  /**
   * Обновляет стандарт
   * @param standard - обновляемые стандарт
   */
  public update(standard: StandardUpdateDto): Observable<StandardDto> {
    return this.http.put<StandardDto>(`${ApiPaths.Standards}/${standard.id}`, standard);
  }

  public restore(id: number): Observable<StandardDto> {
    return this.http.post<StandardDto>(`${ApiPaths.Standards}/unarchive/${id}`, {});
  }

  /**
   * Возвращает картинку стандарта
   * @param id - id стандарта
   */
  public getImageAsync(id: number): Observable<Blob> {
    return this.http.get(`${ApiPaths.Standards}/${id}/image`, {
      responseType: 'blob',
    });
  }

  /**
   * Возвращает иконку прредпросмотра стандарта
   * @param standardId - id
   */
  public getImage(standardId: number) {
    return this.getImageAsync(standardId).pipe(
      take(1),
      map((data) =>
        data.size
          ? {
              file: data,
              styleUrl: this._sanitized.bypassSecurityTrustStyle('url(' + URL.createObjectURL(data) + ')'),
              url: this._sanitized.bypassSecurityTrustUrl(URL.createObjectURL(data)),
              resourceUrl: this._sanitized.bypassSecurityTrustResourceUrl(URL.createObjectURL(data)),
            }
          : null,
      ),
      catchError(() => of(null)),
    );
  }

  /**
   * Загружает файл в хранилище
   * @param id - ID стандарта
   * @param image - файл картинки
   * @param forceUpdate - Флаг обновления изображения
   */

  public uploadImage(id: number, image: File, forceUpdate = true): Observable<void> {
    const formData = new FormData();
    formData.append('file', image);

    return this.http.post<void>(`${ApiPaths.Standards}/${id}/image?forceUpdate=${forceUpdate}`, formData);
  }
}
