import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, of, tap } from 'rxjs';
import { CacheService } from '@core/services/cache.service';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  cacheUrlsInclude = ['assets', 'photo', 'image'];
  constructor(private cacheService: CacheService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const cached = this.cacheService.requestsCache.get(request.urlWithParams);
    if (cached !== undefined && cached?.type !== 0) {
      return of(cached);
    }
    return next
      .handle(request)
      .pipe(
        tap((response) =>
          this.cacheUrlsInclude.some((word) => request.urlWithParams.includes(word))
            ? this.cacheService.requestsCache.set(request.url, response)
            : response,
        ),
      );
  }
}
