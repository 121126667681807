import {
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  SimpleChanges,
  SkipSelf,
  ViewEncapsulation,
} from '@angular/core';

import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  FormArray,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators,
} from '@angular/forms';

import { orderBy } from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { DictionariesService } from '@services';
import { AttributeDto, AttributeValueDto } from '@common/dto';
import { AttributeType } from '@common/enums';
import { sortBySortNumber } from '@common/utils';

import { IAttributeFormItem, TypedAttributeItemFormGroup, TypedAttributesForm } from './attributes-control.model';
import { getAttributeName, getDisplayOptionsForAttribute } from './utils';
import { attributesValidator } from './attribute-validator';
import { CanRemoveFunction } from './types';
import { YandexMetrikaService } from '@core/metrika';

@Component({
  selector: 'app-attributes-control',
  templateUrl: './attributes-control.component.html',
  styleUrls: ['./attributes-control.component.scss'],
  // tslint:disable-next-line:no-host-metadata-property
  host: { class: 'attributes-control' },
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AttributesControlComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: AttributesControlComponent,
    },
  ],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: (container: ControlContainer) => container,
      deps: [[new SkipSelf(), ControlContainer]],
    },
  ],
})
export class AttributesControlComponent implements OnChanges, OnDestroy, ControlValueAccessor, Validators {
  @Input()
  entityAttributes: AttributeValueDto[];

  @Input()
  canRemove: CanRemoveFunction;

  @Input()
  canAdd: boolean;

  @Input()
  canUpdate: boolean;

  @Input()
  emptyOptions: boolean;

  @Input()
  showDescription: boolean;

  @Input()
  set originalAttributesList(list: AttributeDto[]) {
    if (Array.isArray(list)) {
      this._originalAttributesList = list.map((a) => {
        const updatedAttribute = { ...a };
        if (a?.options) {
          updatedAttribute.options = orderBy(a.options, 'name', 'asc');
        }
        return updatedAttribute;
      });
    }
  }

  get originalAttributesList() {
    return this._originalAttributesList;
  }

  form: TypedAttributesForm;
  AttributeType = AttributeType;
  attributesList: AttributeDto[];
  isDisabled: boolean;
  isSomeAttrRemoved: boolean = false;
  _onChange: (value: IAttributeFormItem[]) => void;
  _onTouched: () => void;

  private _destroy$: Subject<any>;
  private _originalAttributesList: AttributeDto[] = [];

  constructor(
    private dictionaryService: DictionariesService,
    private metrika: YandexMetrikaService,
    @Optional() public parentFormGroup: FormGroupDirective,
  ) {
    this.canAdd = true;
    this.canUpdate = true;

    this._destroy$ = new Subject();
    this.originalAttributesList = [];
    this.attributesList = [];
    this._createForm();
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return attributesValidator(control);
  }

  async writeValue(value: AttributeValueDto[]) {
    if (value) {
      this.entityAttributes = value;
      this._updateForm(value);
    }
  }

  registerOnChange(fn: () => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.originalAttributesList && changes.originalAttributesList.currentValue) {
      this._updateAttributesList();

      if (changes.originalAttributesList.currentValue.length > 0) {
        if (this.entityAttributes) {
          this.entityAttributes.forEach((a) => {
            const displayOptions = getDisplayOptionsForAttribute(a, this.originalAttributesList);
            const control = this.form.controls.find((c) => c.value.id === a.id);

            if (control) {
              control.patchValue({ displayOptions }, { emitEvent: false });
            }
          });
        }
      }
    }

    if (changes.entityAttributes && changes.entityAttributes.currentValue) {
      this._updateForm(this.entityAttributes);
    }
  }

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  trackByIndex(index) {
    return index;
  }

  onDrawerClose(): void {
    this.isSomeAttrRemoved = false;
  }

  addEmptyAttribute(attr: AttributeDto) {
    const attrGroup = this._createAttrFormGroup();
    attrGroup.patchValue({
      attributeId: attr.id,
      valueStr: attr.defaultValue,
      attributeName: attr.name,
      attributeType: attr.attributeType,
      displayOptions: attr.options,
      options: [],
    });

    if (attr.attributeType === AttributeType.String) {
      attrGroup.controls.valueStr.setValidators([Validators.required, Validators.maxLength(255)]);
      attrGroup.controls.options.updateValueAndValidity();
    }

    if (attr.attributeType === AttributeType.StringList) {
      attrGroup.controls.options.setValidators([Validators.required]);
      attrGroup.controls.options.updateValueAndValidity();
    }

    this.form.controls.push(attrGroup);
    this._nextChangeValue();
    attrGroup.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(() => this._nextChangeValue());

    this._updateAttributesList();

    this.metrika.action('FAMILY_ADD_ATTRIBUTE');
  }

  removeAttribute(attrFormGroup: TypedAttributeItemFormGroup, index: number): void {
    if (attrFormGroup.value.exist) {
      const control = this.form.controls[index];
      control.patchValue({ removed: true });
    } else {
      this.form.removeAt(index);
    }

    this._nextChangeValue();
    this._updateAttributesList();
    this.checkIsSomeAttrRemoved();
  }

  restoreAttribute(attrFormGroup: TypedAttributeItemFormGroup): void {
    attrFormGroup.patchValue({ removed: false });
    attrFormGroup.enable();
    this.checkIsSomeAttrRemoved();
  }

  get invalid(): boolean {
    return this.form.controls.some((c) => c.invalid);
  }

  private _createForm(): void {
    this.form = new FormArray([]);

    this.form.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(() => {
      this._nextChangeValue();
    });

    this.form.statusChanges.pipe(takeUntil(this._destroy$)).subscribe(() => {
      if (this.form.touched) {
        this._onTouched();
      }
    });
  }

  private _updateForm(attributes: AttributeValueDto[] = []): void {
    if (attributes) {
      this._createForm();

      sortBySortNumber([...attributes]).forEach((attr: AttributeValueDto) => {
        const attrGroup = this._createAttrFormGroup(attr);

        if (attr.attributeType === AttributeType.String) {
          attrGroup.controls.valueStr.setValidators([Validators.required, Validators.maxLength(255)]);
        }

        if (attr.attributeType === AttributeType.StringList) {
          attrGroup.controls.options.setValidators(Validators.required);
        }

        this.form.controls.push(attrGroup);

        attrGroup.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(() => this._nextChangeValue());
        setTimeout(() => {
          this._nextChangeValue();
        });
      });

      if (!this.canUpdate) {
        this.form.disable();
      }

      this._updateAttributesList();
    }
  }

  checkIsSomeAttrRemoved(): void {
    this.isSomeAttrRemoved = this.form.controls.some((i) => i.value.removed);
  }

  private _createAttrFormGroup(attr?: any): TypedAttributeItemFormGroup {
    const canRemove = attr && this.canRemove ? this.canRemove(attr) : true;
    let options: number[] = [];
    if (!this.emptyOptions) {
      options = (attr ? attr.options : []).map((x) => x.id || x);
    }
    const displayOptions = getDisplayOptionsForAttribute(attr, this.originalAttributesList);
    const attributeName = getAttributeName(attr, this.originalAttributesList);
    return new FormGroup({
      id: new FormControl(attr ? attr.id : null),
      attributeId: new FormControl(attr ? attr.attributeId : null, Validators.required),
      valueStr: new FormControl(attr ? attr.valueStr : ''),
      attributeName: new FormControl(attributeName),
      attributeType: new FormControl(attr ? attr.attributeType : null),
      exist: new FormControl(!!attr),
      options: new FormControl(options),
      displayOptions: new FormControl(displayOptions),
      removed: new FormControl(false),
      canRemove: new FormControl(canRemove),
      originalAttribute: new FormControl(attr),
    }) as TypedAttributeItemFormGroup;
  }

  private _updateAttributesList(): void {
    const attrIds = this.form.getRawValue().map((attr) => attr.attributeId);
    this.attributesList = this.originalAttributesList.filter((item) => !attrIds.includes(item.id));
  }

  private _nextChangeValue(): void {
    if (this._onChange) {
      this._onChange(this.form.getRawValue());
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }
}
