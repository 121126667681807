import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FamilyChatService } from '@services';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { ChatParams, MessageDto } from '@common/dto';
import { forkJoin, Subject } from 'rxjs';
import { PikDrawerComponent } from '@pik-ui/ng-components';
import { PermissionService } from '@core/account/services';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() title: string;
  @Input() showAttach = false;
  public chatParams: ChatParams;
  public showMessages: MessageDto[] = [];
  public allMessages: MessageDto[] = [];

  @ViewChild('drawer', { static: true }) drawer: PikDrawerComponent;

  private _destroy$ = new Subject();

  constructor(public permissionService: PermissionService, private familyChatService: FamilyChatService) {}

  ngOnChanges(changes: SimpleChanges): void {
    const openChanges = changes['open'];
    if (openChanges && openChanges.currentValue) {
      this.drawer.open();
    }
  }

  ngOnInit(): void {
    forkJoin([this.subscribeToChatList(), this.subscribeToChatParams()]).pipe(takeUntil(this._destroy$)).subscribe();
  }

  ngAfterViewInit() {
    this.scrollToBottom();
  }

  ngOnDestroy(): void {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  sended(message: MessageDto) {
    this.allMessages.push(message);
    this.scrollToBottom();
  }

  scrollToBottom() {
    setTimeout(() => {
      const drawer = this.drawer as any;
      drawer.scrollbar.directiveRef.scrollToBottom(null, 200);
    });
  }

  private subscribeToChatList() {
    return this.familyChatService.familyChatList.pipe(
      filter((r) => !!r),
      tap((data: MessageDto[]) => {
        this.allMessages = data;
        this.showMessages = data;
        this.scrollToBottom();
      }),
      takeUntil(this._destroy$),
    );
  }

  private subscribeToChatParams() {
    return this.familyChatService.subscribeToChatParams().pipe(
      filter((f) => !!f),
      tap((data) => (this.chatParams = data)),
    );
  }

  systemMessageHandler(systemOn) {
    this.showMessages = systemOn ? this.allMessages.filter((m) => m.isSystem) : this.allMessages;
    this.scrollToBottom();
  }

  trackByIndex(index: number) {
    return index;
  }
}
