import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DisciplineDto, DisciplineTableDto, NameValuesDto } from '@common/dto';
import { DisciplinesFilterParams } from '@common/models/filters';
import { CrudService, ResultPart } from '@common/interfaces';
import { ApiPaths } from '@common/consts';

@Injectable({ providedIn: 'root' })
export class DisciplinesApiService implements CrudService<DisciplineDto> {
  constructor(private http: HttpClient) {}

  public getList(filter?: DisciplinesFilterParams): Observable<ResultPart<DisciplineTableDto>> {
    const params = filter ? filter.toApiParams() : undefined;
    return this.http.post<ResultPart<DisciplineTableDto>>(`${ApiPaths.Disciplines}/filter`, params);
  }

  public getAll() {
    return this.getList(new DisciplinesFilterParams({ limit: -1 }));
  }

  public getById(entityId: number): Observable<DisciplineDto> {
    return this.http.get<DisciplineDto>(`${ApiPaths.Disciplines}/${entityId}`);
  }

  public create(entity: DisciplineDto): Observable<DisciplineDto> {
    return this.http.post<DisciplineDto>(ApiPaths.Disciplines, entity);
  }

  public update(entity: DisciplineDto): Observable<DisciplineDto> {
    return this.http.put<DisciplineDto>(`${ApiPaths.Disciplines}/${entity.id}`, entity);
  }

  public restore(id: number): Observable<DisciplineDto> {
    return this.http.post<DisciplineDto>(`${ApiPaths.Disciplines}/unarchive/${id}`, {});
  }

  public remove(entityId: number): Observable<void> {
    return this.http.delete<void>(`${ApiPaths.Disciplines}/${entityId}`);
  }

  /**
   * Возвращает атрибуты всех вложенных в дисциплину семейств
   * @param entityId - Id дисциплины
   */
  public getAttributes(entityId: number): Observable<NameValuesDto[]> {
    return this.http.get<NameValuesDto[]>(`${ApiPaths.Disciplines}/${entityId}/attributes`);
  }
}
