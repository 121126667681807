import { IAttributeFormItem } from '@shared/components/controls/attributes-control/attributes-control.model';
import { AttributeUpdateDto } from '@common/dto';
import { transformToIds } from '../transform-to-ids';

export function generateAttributeValues(
  attributes: IAttributeFormItem[],
  ownerId,
  optionsIsIds = true,
): AttributeUpdateDto[] {
  return (
    attributes
      ?.filter((a) => !a.removed)
      .map((a) => ({
        id: a.id,
        attributeId: a.attributeId,
        valueStr: a.valueStr,
        isRequiredForMaterial: a.isRequiredForMaterial,
        isRequiredForModerator: a.isRequiredForModerator,
        options: optionsIsIds
          ? transformToIds(a.options)
          : transformToIds(a.options).map((o) => ({ id: o, name: null })),
        ownerId,
      }))
      .map((a) => {
        if (!a.id) {
          delete a.id;
        }
        return a;
      }) || []
  );
}
