import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AttachmentDto } from '@common/dto';
import { environment } from 'environments/environment';
import { BehaviorSubject, finalize, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChatAttachmentsService {
  pending$ = new BehaviorSubject(false);
  attachments = [];
  constructor(private http: HttpClient) {}

  public attachToMessage(id: number): Observable<AttachmentDto[]> {
    this.pending$.next(true);
    const formData = new FormData();
    this.attachments.forEach((file) => formData.append('files', file));
    return this.http.post<AttachmentDto[]>(environment.fmEndpoint + `/messages/${id}/attachments`, formData).pipe(
      tap(() => (this.attachments = [])),
      finalize(() => this.pending$.next(false)),
    );
  }

  public getAttachments(id: number): Observable<AttachmentDto[]> {
    return this.http.get<AttachmentDto[]>(environment.fmEndpoint + `/messages/${id}/attachments`);
  }

  public loadAttachmentById(file: AttachmentDto, messageId: number) {
    this.http
      .get(environment.fmEndpoint + `/Messages/${messageId}/attachments/${file.id}`, {
        responseType: 'blob',
      })
      .pipe(
        tap((attachment: any) => {
          saveAs(attachment, file.fileName);
        }),
      )
      .subscribe();
  }

  public downloadAllAttachment(messageId: number) {
    this.http
      .get(environment.fmEndpoint + `/Messages/${messageId}/attachments/batch`, {
        responseType: 'blob',
      })
      .pipe(
        tap((attachment: any) => {
          saveAs(attachment, `Files ${messageId}`);
        }),
      )
      .subscribe();
  }
}
