import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { CarouselService } from '@services';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageDataInterface } from '@common/interfaces';

@Component({
  selector: 'app-image-loader',
  templateUrl: './image-loader.component.html',
  styleUrls: ['./image-loader.component.scss'],
})
export class ImageLoaderComponent {
  @Input() isChangeAllowed = true;
  @Input() isPending;
  @Input() image: ImageDataInterface;
  @Input() defaultImage: string | undefined;
  @Input() entityType: 'family' | 'functionalType' | 'material' | 'folder' | 'template';
  @Input() mode: 'view' | 'editor' = 'editor';
  @Input() size: 'small' | 'medium' | 'large' = 'medium';

  @Output() setImage = new EventEmitter<File>();

  @ViewChild('fileInput')
  fileInput: ElementRef<HTMLInputElement>;

  constructor(private _carouselService: CarouselService, private _sanitized: DomSanitizer) {}

  showImage(image: ImageDataInterface) {
    if (image.file.type === 'image/svg+xml' || image.default) {
      return;
    }
    const fileName = 'image.png';
    this._carouselService.openImageFile(image.file, fileName);
  }

  public handleFileInput(files: FileList) {
    const file = files[0];

    this.image = {
      file,
      styleUrl: this._sanitized.bypassSecurityTrustStyle('url(' + URL.createObjectURL(file) + ')'),
    };

    this.setImage.emit(file);
  }

  reloadImage() {
    this.fileInput.nativeElement.click();
  }
}
