import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ResultPart } from '@common/interfaces';
import {
  AddFamilyFunctionalTypeDto,
  AttachmentDto,
  DefinitionRuleDto,
  FamilyDto,
  IdNameDto,
  PatchOperation,
  TableFamilyDto,
  VersionLightDto,
} from '@common/dto';
import { FamiliesFilterParams } from '@common/models/filters';
import { ApiPaths } from '@common/consts/api-paths';

@Injectable({ providedIn: 'root' })
export class FamiliesApiService {
  constructor(private http: HttpClient) {}

  public getList(filter?: FamiliesFilterParams) {
    const params = filter ? filter.toApiParams() : {};
    return this.http.post<ResultPart<TableFamilyDto>>(`${ApiPaths.Families}/filter`, params);
  }

  public getById(familyId: number, functionalTypeId?: number) {
    let path = `${ApiPaths.Families}/${familyId}`;

    if (functionalTypeId !== undefined) {
      path += `?functionalTypeId=${functionalTypeId}`;
    }

    return this.http.get<FamilyDto>(path);
  }

  public search(searchString: string = '') {
    return this.http.get<FamilyDto[]>(`${ApiPaths.Families}/search?searchString=${searchString}`);
  }

  public getFTListWithDefinitionRules(familyId: number): Observable<DefinitionRuleDto[]> {
    return this.http.get<DefinitionRuleDto[]>(`${ApiPaths.Families}/${familyId}/familyFunctionalTypes`);
  }

  public smartSearch(query: string): Observable<IdNameDto[]> {
    return this.http.get<IdNameDto[]>(`${ApiPaths.Families}/smartSearch`, {
      params: {
        searchString: query,
      },
    });
  }

  public update(familyId: number, updateDto: Partial<FamilyDto>) {
    return this.http.put<FamilyDto>(`${ApiPaths.Families}/${familyId}`, updateDto);
  }

  public addFunctionalType(data: AddFamilyFunctionalTypeDto) {
    return this.http.put<FamilyDto>(`${ApiPaths.Families}/functionalTypes`, data);
  }

  public removeFunctionalType(familyId: number, functionalTypeId: number) {
    return this.http.delete(`${ApiPaths.Families}/${familyId}/functionalTypes/${functionalTypeId}`);
  }

  public patch(familyId: number, patchData: PatchOperation[]): Observable<FamilyDto> {
    return this.http.patch<FamilyDto>(`${ApiPaths.Families}/${familyId}`, patchData);
  }

  public batchDelete(listIds: number[]) {
    return this.http.post(`${ApiPaths.Families}/batchDelete`, listIds);
  }

  public getFamilyVersions(familyId: number) {
    return this.http.get<VersionLightDto[]>(`${ApiPaths.Families}/${familyId}/versions`);
  }

  public getAttachments(familyId: number): Observable<AttachmentDto[]> {
    return this.http.get<AttachmentDto[]>(`${ApiPaths.Families}/${familyId}/attachments`);
  }

  public async downloadAttachment(familyId: number, attachmentId: number, fileName: string) {
    const file = await this.getAttachment(familyId, attachmentId).toPromise();
    saveAs(file, fileName);
  }

  public async downloadAllAttachments(familyId: number) {
    const file = await this.getAllAttachments(familyId).toPromise();
    saveAs(file, `Вложения семейства ${familyId}`);
  }

  public getAttachment(familyId: number, attachmentId: number) {
    return this.http.get(this.getAttachmentUrl(familyId, attachmentId), {
      responseType: 'blob',
    });
  }

  public getAttachmentUrl(familyId: number, attachmentId: number): string {
    return `${ApiPaths.Families}/${familyId}/attachments/${attachmentId}`;
  }

  public getAllAttachments(familyId: number) {
    return this.http.get(`${ApiPaths.Families}/${familyId}/attachments/batch`, {
      responseType: 'blob',
    });
  }

  public removeAttachment(familyId: number, attachmentId: number) {
    return this.http.delete<{ id: number; name: string }[]>(
      `${ApiPaths.Families}/${familyId}/attachments/${attachmentId}`,
    );
  }

  public loadAttachments(familyId: number, files: File[]): Observable<AttachmentDto[]> {
    const formData = new FormData();
    files.forEach((file) => formData.append('files', file));
    return this.http.post<AttachmentDto[]>(`${ApiPaths.Families}/${familyId}/attachments`, formData);
  }

  public getIdNames(ids: number[]): Observable<IdNameDto[]> {
    return this.http.get<IdNameDto[]>(`${ApiPaths.Families}/idnames`, {
      params: {
        ids: ids.map((id) => id.toString()),
      },
    });
  }

  public createGoogleSheet(filter: FamiliesFilterParams, includeAllVersions: boolean): Observable<string> {
    const params = filter ? filter.toApiParams() : {};
    return this.http.post(`${ApiPaths.Families}/sheets?includeAllVersions=${includeAllVersions}`, params, {
      responseType: 'text',
    });
  }

  public importSheet(sheetId: string): Observable<void> {
    return this.http.put<void>(`${ApiPaths.Families}/sheets/${sheetId}`, null);
  }

  public createExcelSheet(filter: FamiliesFilterParams, includeAllVersions: boolean): Observable<Blob> {
    const params = filter ? filter.toApiParams() : {};
    return this.http.post(`${ApiPaths.Families}/excel?includeAllVersions=${includeAllVersions}`, params, {
      responseType: 'blob',
    });
  }

  public importExcelSheet(file: File) {
    const data = new FormData();
    data.append('file', file);
    return this.http.put(`${ApiPaths.Families}/excel`, data);
  }
}
