import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'chat-file-item',
  templateUrl: './chat-file-item.component.html',
  styleUrls: ['./chat-file-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatFileItemComponent {
  @Input() name: string;
  @Input() link: string;
  @Input() removable: boolean;
  @Output() remove = new EventEmitter();
  @Output() open = new EventEmitter();
}
