import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { PermissionService } from '@core/account/services';
import { ModalService, ToastService } from '@services';
import { filter, take, tap } from 'rxjs/operators';
import { ChatAttachmentsService } from './chat-attachments.service';

@Component({
  selector: 'app-chat-attachments',
  templateUrl: './chat-attachments.component.html',
  styleUrls: ['./chat-attachments.component.scss'],
})
export class ChatAttachmentsComponent {
  @Input() removable = false;
  @Input() permissionUpload = false;
  @Input() permissionDownload = true;
  @Input() pending = false;
  @Output() upload = new EventEmitter<File[]>();

  @ViewChild('fileInput')
  fileInput: ElementRef<HTMLInputElement>;

  constructor(
    public permissionService: PermissionService,
    public modalService: ModalService,
    private toastService: ToastService,
    public chatAttachmentsService: ChatAttachmentsService,
    public cdr: ChangeDetectorRef,
  ) {}

  removeHandler(file, index) {
    this.modalService
      .confirm('COMPONENT_ATTACHMENTS.CONFIRM_QUESTION')
      .pipe(
        take(1),
        filter((f) => f),
        tap(() => {
          this.chatAttachmentsService.attachments.splice(index, 1);
          this.cdr.detectChanges();
        }),
      )
      .subscribe();
  }

  public addFile() {
    this.fileInput.nativeElement.click();
  }

  public uploadFiles(fileList: FileList) {
    const files: File[] = Array.from(fileList);

    if (!files.length) {
      return;
    }

    if (files.some((file) => file.size > 104857600)) {
      this.toastService.warning(
        { value: 'COMPONENT_ATTACHMENTS.SIZE_LIMIT_ERROR.MESSAGE' },
        { value: 'COMPONENT_ATTACHMENTS.SIZE_LIMIT_ERROR.TITLE' },
      );
      return;
    }
    this.chatAttachmentsService.attachments = files;
  }

  downloadFile(file: File) {
    saveAs(file, file.name);
  }
}
