import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ImagesViewerService } from '@shared/components/markdown/utils/images-viewer.service';

@Injectable()
export class ReplaceImageInterceptor implements HttpInterceptor {
  constructor(public imagesViewerService: ImagesViewerService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    try {
      if (request.method === 'PUT' && !!request.body?.['description']) {
        request.body['description'] = this.imagesViewerService.replaceImagesToSave(request.body['description']);
      }
      if (request.method === 'PATCH' && Array.isArray(request.body)) {
        const item = request.body.find((item) => item.path === 'description');
        if (item?.value) {
          item.value = this.imagesViewerService.replaceImagesToSave(item.value);
        }
      }
    } catch (e) {
      return next.handle(request);
    }
    return next.handle(request);
  }
}
