import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { take, tap } from 'rxjs/operators';

import { FamilyChatService } from '@services';
import { ChatParams, MessageDto, UserDto } from '@common/dto';
import { ImagesViewerService } from '@shared/components/markdown/utils/images-viewer.service';
import { ChatAttachmentsService } from '../chat-attachments/chat-attachments.service';

@Component({
  selector: 'app-chat-message-form',
  templateUrl: './chat-message-form.component.html',
  styleUrls: ['./chat-message-form.component.scss'],
})
export class ChatMessageFormComponent {
  @Input() chatParams: ChatParams;
  @Input() showAttach = false;

  @Output() sended = new EventEmitter<MessageDto>();

  form: UntypedFormGroup = new UntypedFormGroup({
    message: new UntypedFormControl(null, Validators.required),
    additionalReceiverIds: new UntypedFormControl([]),
  });

  constructor(
    private familyChatService: FamilyChatService,
    private cdr: ChangeDetectorRef,
    private сhatAttachmentsService: ChatAttachmentsService,
    public imagesViewerService: ImagesViewerService,
  ) {}

  onChangeReceivers(receivers: UserDto[]) {
    const ids = receivers.map((item) => item.id);
    this.form.controls.additionalReceiverIds.setValue(ids);
    setTimeout(() => {
      this.cdr.detectChanges();
    }, 500);
  }

  public formSubmit() {
    const message = {
      message: this.imagesViewerService.replaceImagesToSave(this.form.value?.message?.trim() || ''),
      additionalReceiverIds: this.form.value.additionalReceiverIds,
    } as MessageDto;
    if (this.chatParams.chatMessageType) {
      message.chatMessageType = this.chatParams.chatMessageType;
    }
    if (this.chatParams.messageObjectId) {
      message.messageObjectId = this.chatParams.messageObjectId;
    }
    if (this.form.valid || this.сhatAttachmentsService.attachments.length) {
      message['chatMessageTypeId'] = message.chatMessageType;
      this.familyChatService
        .sendMessage(message)
        .pipe(
          take(1),
          tap((m) => {
            if (this.showAttach) {
              this.сhatAttachmentsService.attachToMessage(m.id).subscribe(() => {
                this.sended.emit(m);
                this.cdr.detectChanges();
              });
            } else {
              this.sended.emit(m);
            }
          }),
        )
        .subscribe();
      this.form.reset();
    }
  }
}
