import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';

import TurndownService from 'turndown';
import * as MarkdownIt from 'markdown-it';
import Quill from '@pik-ui/quill/quill.min';
import './mention/quill-mention/quill.mention';
import MagicUrl from 'quill-magic-url';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputBoolean } from '@pik-ui/ng-components';
import { Overlay } from '@angular/cdk/overlay';
import { fromEvent, Subscription } from 'rxjs';

import { HeapFilesApiService, UsersApiService } from '@services/api';
import { UserDto } from '@common/dto';
import { uuidv4 } from '@common/utils';

import { MentionOptions, MentionService } from './mention';
import { quillConfigFactory } from './quill-config-factory';
import { ToolbarButtons } from './toolbar';
import { ImagesViewerService } from '../utils/images-viewer.service';
import { ChatAttachmentsComponent } from '@shared/components/chat/components/chat-attachments/chat-attachments.component';

Quill.register('modules/magicUrl', MagicUrl);

const turndownService: TurndownService = new TurndownService({
  defaultReplacement: (content, node: HTMLElement) => {
    if (node.classList.contains('mention')) {
      const { denotationChar, id, value } = node.dataset;
      return `[${denotationChar}${value}](${location.origin}/users/${id})`;
    }

    return content;
  },
});

const md = MarkdownIt();
md.set({ html: true });

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'markdown-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MarkdownEditorComponent),
      multi: true,
    },
  ],
})
export class MarkdownEditorComponent implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor {
  @Input() showAttach = false;
  @Input()
  set value(newValue: string) {
    this.writeValue(newValue);
  }
  get value(): string {
    return this._value;
  }

  @Input() minWidth: string;
  @Input() maxWidth: string;
  @Input() minHeight: string;
  @Input() maxHeight: string;

  @Input()
  @InputBoolean()
  hideToolbar: boolean;

  @Input()
  @InputBoolean()
  outlined: boolean;

  @Input()
  set disabled(disabled: boolean) {
    if (disabled !== this._disabled) {
      this._disabled = disabled;

      if (this._editor) {
        this._editor.enable(!disabled);
      }
    }
  }
  get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  @InputBoolean()
  useImages = false;

  @Input()
  @InputBoolean()
  useMention = false;

  @Input()
  mentionOptions = new MentionOptions();

  @Input()
  @InputBoolean()
  inlineMode: boolean;

  @Input()
  placeholder: string;

  @Output()
  change = new EventEmitter<string>();

  @Output()
  blurred = new EventEmitter();

  @Output()
  focused = new EventEmitter();

  @Output()
  mentionChange = new EventEmitter<UserDto[]>();

  @ViewChild('editorRef', { static: true })
  _editorRef: ElementRef<HTMLElement>;

  @ViewChild('attachments', { static: true })
  _attachments: ChatAttachmentsComponent;

  @ViewChild('toolbarRef', { static: true })
  _toolbarRef: ElementRef<HTMLElement>;

  @ViewChild('mentionDropdownRef')
  _dropdownTemplateRef: TemplateRef<any>;

  previewLink = '';
  insertingLink = false;
  toolbarButtons = [];
  mentionService: MentionService;
  scrollbarId = uuidv4();
  _focused: boolean;

  private _attachmentsCount = 1;
  private _disabled: boolean;
  private _value: string;
  private _valueHtml: string;
  private _editor: any;
  private _editorOptions: any;
  private _subscribers: Subscription;

  constructor(
    private _heapFilesApi: HeapFilesApiService,
    private _changeDetector: ChangeDetectorRef,
    private _imagesViewer: ImagesViewerService,

    // don't remove
    public _userService: UsersApiService,
    public _viewContainerRef: ViewContainerRef,
    public _overlay: Overlay,
  ) {
    this.maxHeight = '500px';
    this.maxWidth = '100%';

    this._valueHtml = '';
    this._subscribers = new Subscription();
  }

  onChange = (value: string) => {};
  onTouched = () => {};

  writeValue(value: string): void {
    if (this.value !== value) {
      this._value = value || '';
      this._valueHtml = md.render(this._value);
      if (this._editor) {
        const converted = this._editor.clipboard.convert({ html: this._valueHtml });
        this._editor.setContents(converted);
      }
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  ngOnInit() {
    this.toolbarButtons = ToolbarButtons.filter((item) => {
      if (!this.showAttach && item.class === 'ql-file') {
        return false;
      }

      if (!this.useMention && item.class === 'ql-mention') {
        return false;
      }

      if (!this.useImages && item.class === 'ql-image') {
        return false;
      }

      return true;
    });
  }

  ngAfterViewInit() {
    const editorOptions = quillConfigFactory.call(this, this.useImages, this.useMention, this.scrollbarId);
    this._editorOptions = editorOptions;

    this._editor = new Quill(this._editorRef.nativeElement, this._editorOptions);

    if (this.useMention) {
      this.mentionService = new MentionService(this, this.mentionOptions);
      this._subscribers.add(
        this.mentionService.mentionListChange.subscribe((list) => {
          this.mentionChange.emit(list);
        }),
      );
    }

    if (this.disabled) {
      this._editor.enable(!this.disabled);
    }

    const converted = this._editor.clipboard.convert({ html: this._valueHtml });
    this._editor.setContents(converted);

    this._editor.on('text-change', (delta: any, oldContents: any, source: string) => {
      this._onTextChange(delta, oldContents, source);
    });

    this._subscribers.add(fromEvent(this.editorElement, 'focus').subscribe(this._onFocus));
    this._subscribers.add(fromEvent(this.editorElement, 'blur').subscribe(this._onBlur));

    this._imagesViewer.init(this.editorElement.querySelectorAll('img'));
  }

  ngOnDestroy() {
    this._subscribers.unsubscribe();

    if (this.mentionService) {
      this.mentionService.destroy();
    }

    this._editor.blur();
  }

  get editorElement(): HTMLElement {
    return this._editor.container.querySelector('.ql-editor');
  }

  public focus() {
    if (this.editorElement) {
      this.editorElement.focus();
    }
  }

  public blur() {
    if (this.editorElement) {
      this.editorElement.blur();
    }
  }

  public getHTMLString(): string {
    return this._editor.getSemanticHTML();
  }

  public getMarkdown(): string {
    const editorHtmlContent = this.getHTMLString();
    return turndownService.turndown(editorHtmlContent);
  }

  _inserLink(link: string) {
    this._editor.format('link', link);
    this.previewLink = '';
    this.insertingLink = false;
  }

  _imageHandler() {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'file/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      this._embedImageToEditor(file);
    };
  }

  _imageHandlerPaste = (range, files) => {
    if (this.useImages) {
      const [image] = files;
      const fileName = `image ${this._attachmentsCount++}.png`;

      const file = new File([image], fileName, {
        type: image.type,
        lastModified: image.lastModified,
      });

      this._embedImageToEditor(file);
    }
  };

  private async _embedImageToEditor(file: File) {
    const _embedImage = (imageLink: string | ArrayBuffer) => {
      const { index } = this._editor.getSelection();
      this._editor.insertEmbed(index, 'image', imageLink, 'user');
      this._editor.setSelection(index + 1);
    };

    const link = await this._heapFilesApi.uploadWithLink(file).toPromise();
    _embedImage(link);

    this._imagesViewer.destroy();
    this._imagesViewer.init(this._editor.root.querySelectorAll('img'));
  }

  _startMention() {
    const { index } = this._editor.getSelection();
    this._editor.insertText(index, '@');
    this._editor.setSelection(index + 1);
    this.mentionService.showSuggestionList();
  }

  _linkHandler(value: string) {
    if (!value) {
      const format = this._editor.getFormat();
      this.previewLink = format.link;
    } else {
      this.previewLink = '';
    }

    this.insertingLink = true;
  }

  _fileHandler(value: string) {
    this._attachments.fileInput.nativeElement.click();
  }

  private _onTextChange(delta: any, oldContents: any, source: string) {
    if (this.useMention) {
      this.mentionService.onEditorTextChange(this.getHTMLString());
    }

    this._onChange();
  }

  private _onChange() {
    const mdContent = this.getMarkdown().trim();
    if (this.onChange) {
      setTimeout(() => this.onChange(mdContent));
    }

    this.change.emit(mdContent);
    this._value = mdContent;
    setTimeout(() => {
      if (mdContent) {
        this._imagesViewer.init(this.editorElement.querySelectorAll('img'));
      }
    }, 400);
  }

  private _onFocus = () => {
    setTimeout(() => {
      this._focused = true;
    });
    this.focused.emit();
    this._changeDetector.markForCheck();
  };

  private _onBlur = () => {
    this._focused = false;
    this.blurred.emit();
    this._changeDetector.markForCheck();
  };

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    this.hideToolbar = isDisabled;
  }
}
